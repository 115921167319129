// import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Container,
//   TextField,
//   Button,
//   Typography,
//   Paper,
//   Box,
//   InputAdornment,
// } from "@mui/material";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import { apiRouterCall } from "../utils/apiRouterCall";
// import { Visibility, VisibilityOff } from "@mui/icons-material";

// const LoginPage = () => {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");
//   const [showPassword, setShowPassword] = useState(false);
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     try {
//       const response = await apiRouterCall({
//         endPoint: "loginAdmin",
//         method: "POST",
//         data: {
//           email: username,
//           password: password,
//         },
//       });
//       if (response.status === 200) {
//         toast.success(response?.message);
//         localStorage.setItem("token", response?.data?.data);
//         navigate("/dashboard", { replace: true });
//       } else {
//         toast.error(response?.message);
//       }
//     } catch (error) {
//       console.log(error, "asda");
//       toast.error(error?.response?.data?.message);
//     }
//   };

//   return (
//     <Container maxWidth="xs" sx={{ mt: 5 }}>
//       <Paper sx={{ padding: 3, mt: "200px" }}>
//         <Typography
//           variant="h5"
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             textAlign: "center",
//             alignItems: "center",
//           }}
//           gutterBottom
//         >
//           Login
//         </Typography>
//         <form onSubmit={handleSubmit}>
//           <TextField
//             size="small"
//             placeholder="Email"
//             fullWidth
//             variant="outlined"
//             margin="normal"
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//           />
//           <TextField
//             size="small"
//             placeholder="Password"
//             fullWidth
//             type={showPassword ? "text" : "password"}
//             variant="outlined"
//             margin="normal"
//             value={password}
//             onChange={(e) => setPassword(e.target.value)}
//             InputProps={{
//               endAdornment: (
//                 <InputAdornment position="end">
//                   <Button
//                     sx={{ padding: 0 }}
//                     onClick={() => setShowPassword(!showPassword)}
//                   >
//                     {showPassword ? (
//                       <Visibility sx={{ color: "gray" }} />
//                     ) : (
//                       <VisibilityOff sx={{ color: "gray" }} />
//                     )}
//                   </Button>
//                 </InputAdornment>
//               ),
//             }}
//           />
//           <Box sx={{ mt: 2 }}>
//             <Button
//               variant="contained"
//               color="primary"
//               fullWidth
//               type="submit"
//               sx={{
//                 borderRadius: "19px",
//                 bgcolor: username && password ? "blue" : "#b0c4de",
//                 "&:hover": {
//                   bgcolor: username && password ? "#0056b3" : "#b0c4de",
//                 },
//               }}
//               onClick={handleSubmit}
//               disabled={!username || !password}
//             >
//               Login
//             </Button>
//           </Box>
//         </form>
//       </Paper>
//       <ToastContainer />
//     </Container>
//   );
// };

// export default LoginPage;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Box,
  InputAdornment,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiRouterCall } from "../utils/apiRouterCall";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await apiRouterCall({
        endPoint: "loginAdmin",
        method: "POST",
        data: {
          email: username,
          password: password,
        },
      });
      if (response.status === 200) {
        toast.success(response?.message);
        localStorage.setItem("token", response?.data?.data);
        navigate("/dashboard", { replace: true });
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log(error, "asda");
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 5 }}>
      <Paper sx={{ padding: 3, mt: "200px" }}>
        <Typography
          variant="h5"
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
          gutterBottom
        >
          Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            size="small"
            placeholder="Email"
            fullWidth
            variant="outlined"
            margin="normal"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            size="small"
            placeholder="Password"
            fullWidth
            type={showPassword ? "text" : "password"}
            variant="outlined"
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    sx={{ padding: 0 }}
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <Visibility sx={{ color: "gray" }} />
                    ) : (
                      <VisibilityOff sx={{ color: "gray" }} />
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
              sx={{
                borderRadius: "19px",
                bgcolor: username && password ? "blue" : "#b0c4de",
                "&:hover": {
                  bgcolor: username && password ? "#0056b3" : "#b0c4de",
                },
              }}
              onClick={handleSubmit}
              disabled={!username || !password}
            >
              Login
            </Button>
          </Box>
        </form>
      </Paper>
      <ToastContainer />
    </Container>
  );
};

export default LoginPage;
