// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   TextField,
//   Typography,
//   IconButton,
//   Grid,
//   Container,
//   Select,
//   MenuItem,
// } from "@mui/material";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { toast } from "react-toastify";
// import { apiRouterCall } from "../../utils/apiRouterCall";
// import { useLocation, useParams, useNavigate } from "react-router-dom";
// import { crudType } from "../../utils/const";

// const moduleOptions = [
//   "Homepage banner",
//   "Homepage slider",
//   "Tech_Gallery_1",
//   "Tech_Gallery_2",
//   "Tech_Gallery_3",
//   // "Tech_Gallery_4",
//   "Tech_Gallery_5",
// ];

// const AddBanner = () => {
//   const [banners, setBanners] = useState([]);
//   const [filteredBanners, setFilteredBanners] = useState([]);
//   console.log(banners, "baneeeee");

//   const { type } = useParams();
//   const { state } = useLocation();
//   const navigate = useNavigate();
//   const [images, setImages] = useState([
//     { file: null, title: "", description: "", moduleName: "" },
//   ]);
//   console.log(">>>>>>>>>>>>>", type, state);

//   const uploadFileHandler = (file) => {
//     return new Promise((resolve, reject) => {
//       const formData = new FormData();
//       formData.append("file", file);

//       const toastId = toast.loading("Uploading file...");
//       apiRouterCall({
//         method: "POST",
//         endPoint: "imageUpload",
//         data: formData,
//       })
//         .then((response) => {
//           if (response.status === 200) {
//             toast.update(toastId, {
//               render: "Upload successful!",
//               type: "success",
//               isLoading: false,
//               autoClose: 3000,
//             });
//             resolve(response?.data?.data);
//           } else {
//             throw new Error("Upload failed");
//           }
//         })
//         .catch((error) => {
//           toast.update(toastId, {
//             render: "Failed to upload image.",
//             type: "error",
//             isLoading: false,
//             autoClose: 3000,
//           });
//           reject(error);
//         });
//     });
//   };

//   const handleImageUpload = async (e, index) => {
//     const file = e.target.files[0];
//     if (!file) return;

//     try {
//       const uploadedImage = await uploadFileHandler(file);
//       setImages((prev) =>
//         prev.map((img, idx) =>
//           idx === index ? { ...img, file: uploadedImage } : img
//         )
//       );
//     } catch (error) {
//       console.error("Error uploading file", error);
//     }
//   };

//   const handleImageDetailsChange = (index, field, value) => {
//     setImages((prev) =>
//       prev.map((img, idx) => (idx === index ? { ...img, [field]: value } : img))
//     );
//   };

//   const handleAddBanner = () => {
//     setImages((prev) => [
//       ...prev,
//       { file: null, title: "", description: "", moduleName: "" },
//     ]);
//   };

//   const handleRemoveBanner = (index) => {
//     setImages((prev) => prev.filter((_, idx) => idx !== index));
//   };
//   const handleSubmit = async () => {
//     try {
//       const formattedData = images.map((img) => ({
//         image: img.file,
//         title: img.title,
//         description: img.description,
//         module_name: img.moduleName,
//         _id: state?._id ? state?._id : undefined,
//       }));
//       await apiRouterCall({
//         endPoint:
//           type === crudType.UPDATE
//             ? "editDescription"
//             : "createImageWithDescription",
//         method: "POST",
//         data: formattedData,
//       });
//       toast.success("Banners added successfully!");
//       navigate("/banner-management");
//     } catch (error) {
//       console.error("Submission error", error);
//       toast.error("Failed to add banners.");
//     }
//   };

//   const isSaveDisabled = images.some((img) => !img.file || !img.moduleName);
//   const listCategory = async () => {
//     try {
//       const res = await apiRouterCall({
//         method: "GET",
//         endPoint: "listBannerModule",
//       });
//       if (res.data) {
//         console.log(res.data, "bannersbanners");
//         setBanners(res.data.data);
//         setFilteredBanners(res.data.data);
//       }
//     } catch (error) {}
//   };

//   useEffect(() => {
//     setImages([
//       {
//         file: state?.image,
//         title: state?.title,
//         description: state?.description,
//         moduleName: state?.module_name,
//         _id: state?._id,
//       },
//     ]);
//   }, [state]);

//   useEffect(() => {
//     listCategory();
//   }, []);
//   return (
//     <Container>
//       <Box sx={{ padding: 3, minHeight: "100vh", color: "#333" }}>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//             gap: 2,
//             marginBottom: 3,
//           }}
//         >
//           <IconButton onClick={() => navigate(-1)} color="primary">
//             <ArrowBackIcon />
//           </IconButton>
//           <Typography variant="h5">Add Banner</Typography>
//         </Box>

//         <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 2 }}>
//           {images.map((img, idx) => (
//             <Box
//               key={idx}
//               sx={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: 2,
//                 border: "1px solid #ddd",
//                 borderRadius: 2,
//                 padding: 2,
//                 backgroundColor: "#f9f9f9",
//               }}
//             >
//               <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
//                 {img.file ? (
//                   <img
//                     src={img.file}
//                     alt={`Uploaded ${idx}`}
//                     style={{
//                       width: 70,
//                       height: 70,
//                       borderRadius: 8,
//                       objectFit: "cover",
//                     }}
//                   />
//                 ) : (
//                   <Typography sx={{ fontSize: 14, color: "#666" }}>
//                     No image uploaded
//                   </Typography>
//                 )}
//                 <Button
//                   variant="contained"
//                   component="label"
//                   size="small"
//                   sx={{ bgcolor: "blue" }}
//                 >
//                   Upload Image
//                   <input
//                     type="file"
//                     hidden
//                     onChange={(e) => handleImageUpload(e, idx)}
//                   />
//                 </Button>
//                 <IconButton
//                   size="small"
//                   onClick={() => handleRemoveBanner(idx)}
//                   sx={{
//                     backgroundColor: "rgba(0, 0, 0, 0.1)",
//                   }}
//                 >
//                   <DeleteIcon />
//                 </IconButton>
//               </Box>

//               <Grid container spacing={2}>
//                 <Grid item xs={12} sm={4}>
//                   <TextField
//                     placeholder="Title"
//                     fullWidth
//                     value={img.title}
//                     onChange={(e) =>
//                       handleImageDetailsChange(idx, "title", e.target.value)
//                     }
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <TextField
//                     placeholder="Description"
//                     fullWidth
//                     value={img.description}
//                     onChange={(e) =>
//                       handleImageDetailsChange(
//                         idx,
//                         "description",
//                         e.target.value
//                       )
//                     }
//                   />
//                 </Grid>
//                 <Grid item xs={12} sm={4}>
//                   <Select
//                     fullWidth
//                     value={img.moduleName}
//                     onChange={(e) =>
//                       handleImageDetailsChange(
//                         idx,
//                         "moduleName",
//                         e.target.value
//                       )
//                     }
//                     displayEmpty
//                   >
//                     <MenuItem value="" disabled>
//                       Select Module
//                     </MenuItem>
//                     {moduleOptions.map((option, i) => (
//                       <MenuItem key={i} value={option}>
//                         {option}
//                       </MenuItem>
//                     ))}
//                   </Select>
//                 </Grid>
//               </Grid>
//             </Box>
//           ))}
//         </Box>

//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             gap: 2,
//           }}
//         >
//           <Button variant="outlined" color="primary" onClick={handleAddBanner}>
//             Add Another Banner
//           </Button>

//           <Button
//             variant="contained"
//             color="primary"
//             onClick={handleSubmit}
//             disabled={isSaveDisabled}
//           >
//             Save
//           </Button>
//         </Box>
//       </Box>
//     </Container>
//   );
// };

// export default AddBanner;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Grid,
  Container,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { apiRouterCall } from "../../utils/apiRouterCall";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { crudType } from "../../utils/const";

const AddBanner = () => {
  const [banners, setBanners] = useState([]);
  const [images, setImages] = useState([
    { file: null, title: "", description: "", moduleName: "" },
  ]);

  const { type } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();

  const uploadFileHandler = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append("file", file);

      const toastId = toast.loading("Uploading file...");
      apiRouterCall({
        method: "POST",
        endPoint: "imageUpload",
        data: formData,
      })
        .then((response) => {
          if (response.status === 200) {
            toast.update(toastId, {
              render: "Upload successful!",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
            resolve(response?.data?.data);
          } else {
            throw new Error("Upload failed");
          }
        })
        .catch((error) => {
          toast.update(toastId, {
            render: "Failed to upload image.",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          reject(error);
        });
    });
  };

  const handleImageUpload = async (e, index) => {
    const file = e.target.files[0];
    if (!file) return;

    try {
      const uploadedImage = await uploadFileHandler(file);
      setImages((prev) =>
        prev.map((img, idx) =>
          idx === index ? { ...img, file: uploadedImage } : img
        )
      );
    } catch (error) {
      console.error("Error uploading file", error);
    }
  };

  const handleImageDetailsChange = (index, field, value) => {
    setImages((prev) =>
      prev.map((img, idx) => (idx === index ? { ...img, [field]: value } : img))
    );
  };

  const handleAddBanner = () => {
    setImages((prev) => [
      ...prev,
      { file: null, title: "", description: "", moduleName: "" },
    ]);
  };

  const handleRemoveBanner = (index) => {
    setImages((prev) => prev.filter((_, idx) => idx !== index));
  };

  const handleSubmit = async () => {
    try {
      const formattedData = images.map((img) => ({
        image: img.file,
        title: img.title,
        description: img.description,
        module_name: img.moduleName,
        _id: state?._id ? state?._id : undefined,
      }));
      await apiRouterCall({
        endPoint:
          type === crudType.UPDATE
            ? "editDescription"
            : "createImageWithDescription",
        method: "POST",
        data: formattedData,
      });
      toast.success("Banners added successfully!");
      navigate("/banner-management");
    } catch (error) {
      console.error("Submission error", error);
      toast.error("Failed to add banners.");
    }
  };

  const isSaveDisabled = images.some((img) => !img.file || !img.moduleName);

  const listCategory = async () => {
    try {
      const res = await apiRouterCall({
        method: "GET",
        endPoint: "listBannerModule",
      });
      if (res.data) {
        setBanners(res.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch banners", error);
    }
  };

  useEffect(() => {
    setImages([
      {
        file: state?.image,
        title: state?.title,
        description: state?.description,
        moduleName: state?.module_name,
        _id: state?._id,
      },
    ]);
  }, [state]);

  useEffect(() => {
    listCategory();
  }, []);

  return (
    <Container>
      <Box sx={{ padding: 3, minHeight: "100vh", color: "#333" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            marginBottom: 3,
          }}
        >
          <IconButton
            sx={{ color: "#333" }}
            onClick={() => navigate(-1)}
            color="primary"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">Add Banner</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 2 }}>
          {images.map((img, idx) => (
            <Box
              key={idx}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                border: "1px solid #ddd",
                borderRadius: 2,
                padding: 2,
                backgroundColor: "#f9f9f9",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {img.file ? (
                  <img
                    src={img.file}
                    alt={`Uploaded ${idx}`}
                    style={{
                      width: 70,
                      height: 70,
                      borderRadius: 8,
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <Typography sx={{ fontSize: 14, color: "#666" }}>
                    No image uploaded
                  </Typography>
                )}
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  sx={{ bgcolor: "blue" }}
                >
                  Upload Image
                  <input
                    type="file"
                    hidden
                    onChange={(e) => handleImageUpload(e, idx)}
                  />
                </Button>
                <IconButton
                  size="small"
                  onClick={() => handleRemoveBanner(idx)}
                  sx={{
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    placeholder="Title"
                    fullWidth
                    value={img.title}
                    onChange={(e) =>
                      handleImageDetailsChange(idx, "title", e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    placeholder="Description"
                    fullWidth
                    value={img.description}
                    onChange={(e) =>
                      handleImageDetailsChange(
                        idx,
                        "description",
                        e.target.value
                      )
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Select
                    fullWidth
                    value={img.moduleName}
                    onChange={(e) =>
                      handleImageDetailsChange(
                        idx,
                        "moduleName",
                        e.target.value
                      )
                    }
                    displayEmpty
                  >
                    <MenuItem value="" disabled>
                      Select Module
                    </MenuItem>
                    {banners.map((banner) => (
                      <MenuItem key={banner._id} value={banner.module_name}>
                        {banner.module_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button variant="outlined" color="primary" onClick={handleAddBanner}>
            Add Another Banner
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={isSaveDisabled}
            sx={{ bgcolor: "blue" }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddBanner;
