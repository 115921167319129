import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  IconButton,
  Grid,
  Container,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { apiRouterCall } from "../../utils/apiRouterCall";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { crudType } from "../../utils/const";

const moduleOptions = [
  "Homepage banner",
  "Homepage slider",
  "Tech_Gallery_1",
  "Tech_Gallery_2",
  "Tech_Gallery_3",
  // "Tech_Gallery_4",
  "Tech_Gallery_5",
];

const AddCategory = () => {
  const { type } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [images, setImages] = useState([
    { file: null, title: "", description: "", moduleName: "" },
  ]);
  console.log(">>>>>>>>>>>>>", type, state);

  const handleImageDetailsChange = (index, field, value) => {
    setImages((prev) =>
      prev.map((img, idx) => (idx === index ? { ...img, [field]: value } : img))
    );
  };

  const handleAddBanner = () => {
    setImages((prev) => [
      ...prev,
      { file: null, title: "", description: "", moduleName: "" },
    ]);
  };

  const handleRemoveBanner = (index) => {
    setImages((prev) => prev.filter((_, idx) => idx !== index));
  };
  const handleSubmit = async () => {
    try {
      const formattedData = images.map((img) => ({
        module_name: img.title,
        _id: state?._id ? state?._id : undefined,
      }));
      await apiRouterCall({
        endPoint:
          type === crudType.UPDATE ? "editBannerModule" : "createBannerModule",
        method: "POST",
        data: formattedData,
      });
      toast.success("Category added successfully!");
      navigate("/category-management");
    } catch (error) {
      console.error("Submission error", error);
      toast.error("Failed to edit category.");
    }
  };

  useEffect(() => {
    setImages([
      {
        file: state?.image,
        title: state?.module_name,
        description: state?.description,
        moduleName: state?.module_name,
        _id: state?._id,
      },
    ]);
  }, [state]);

  return (
    <Container>
      <Box sx={{ padding: 3, minHeight: "100vh", color: "#333" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            marginBottom: 3,
          }}
        >
          <IconButton
            sx={{ color: "#333" }}
            onClick={() => navigate(-1)}
            color="primary"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5">Add Category</Typography>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", gap: 3, mb: 2 }}>
          {images.map((img, idx) => (
            <Box
              key={idx}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                border: "1px solid #ddd",
                borderRadius: 2,
                padding: 2,
                backgroundColor: "#f9f9f9",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <TextField
                    placeholder="Enter Category"
                    fullWidth
                    value={img.title}
                    onChange={(e) =>
                      handleImageDetailsChange(idx, "title", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default AddCategory;
