import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  CircularProgress,
  Typography,
  TextField,
  Box,
  Container,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { apiRouterCall } from "../utils/apiRouterCall";

const EnquiryList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [email, setEmail] = useState("admin@yopmail.com");
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await apiRouterCall({
          method: "GET",
          endPoint: "listInquiry",
        });

        if (response.data && Array.isArray(response.data.data.docs)) {
          setUsers(response.data.data.docs);
          setFilteredUsers(response.data.data.docs);
          setTotalDocs(response.data.data.totalDocs);
        } else {
          setError("Invalid response format");
          console.error("Invalid response format", response.data);
        }
      } catch (err) {
        setError("Failed to fetch users.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [email]);

  useEffect(() => {
    const filtered = users.filter(
      (user) =>
        user.name.toLowerCase().includes(searchName.toLowerCase()?.trim()) &&
        user.email.toLowerCase().includes(searchEmail.toLowerCase()?.trim())
    );
    setFilteredUsers(filtered);
  }, [searchName, searchEmail, users]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Container
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <Typography variant="h6" gutterBottom>
          Loading users...
        </Typography>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container>
      <h2>Enquiry Users List</h2>

      <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
        <TextField
          placeholder="Search by name"
          variant="outlined"
          size="small"
          searchName
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
        />
        <TextField
          placeholder="Search by email"
          variant="outlined"
          size="small"
          value={searchEmail}
          onChange={(e) => setSearchEmail(e.target.value)}
        />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h6">Name</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Email</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6">Date / Time</Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6">Details</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{moment(user.createdAt).format("lll")}</TableCell>
                  <TableCell align="right">
                    <Button
                      component={Link}
                      to={`/enquiry/${user._id}`}
                      variant="outlined"
                      color="primary"
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[8, 20, 30]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default EnquiryList;
