// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Button,
//   Container,
//   IconButton,
//   Paper,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Typography,
// } from "@mui/material";
// import DeleteIcon from "@mui/icons-material/Delete";
// import { Visibility, Edit } from "@mui/icons-material";
// import { useNavigate } from "react-router-dom";
// import { crudType } from "../../utils/const";
// import { apiRouterCall } from "../../utils/apiRouterCall";

// const Banner = () => {
//   const [banners, setBanners] = useState([]);
//   console.log(banners, "iuuy");

//   const listBanner = async () => {
//     try {
//       const res = await apiRouterCall({
//         method: "GET",
//         endPoint: "listImage",
//       });
//       if (res.data) {
//         console.log(">>>>>>>>>>>>", res.data);

//         setBanners(res.data.data.docs);
//       }
//     } catch (error) {}
//   };

//   const deleteBanner = async (banner) => {
//     console.log(banner, "hgh");

//     try {
//       const res = await apiRouterCall({
//         method: "POST",
//         endPoint: "deleteImage",
//         data: {
//           _id: banner._id,
//         },
//       });
//       if (res.data) {
//         console.log(">>>>>>>>>>>>", res.data);

//         listBanner();
//       }
//     } catch (error) {}
//   };

//   useEffect(() => {
//     listBanner();
//   }, []);

//   const navigate = useNavigate();

//   return (
//     <Container>
//       <Box
//         sx={{
//           padding: 3,
//           minHeight: "100vh",
//           // color: "#FFF",
//         }}
//       >
//         <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
//           <Typography variant="h5">Banner Management</Typography>
//           <Button
//             sx={{ bgcolor: "blue" }}
//             variant="contained"
//             color="primary"
//             onClick={() => navigate(`/add-banner/${crudType.ADD}`)}
//           >
//             Add Banner
//           </Button>
//         </Box>
//         <Paper>
//           <TableContainer>
//             <Table>
//               <TableHead>
//                 <TableRow>
//                   <TableCell>Module Name</TableCell>
//                   <TableCell> Images</TableCell>
//                   <TableCell>Date Uploaded</TableCell>
//                   <TableCell>Action</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {banners &&
//                   banners?.map((banner) => (
//                     <TableRow key={banner.id}>
//                       <TableCell>{banner?.module_name}</TableCell>
//                       <TableCell>
//                         <Box sx={{ display: "flex", gap: 1 }}>
//                           <img
//                             src={banner?.image}
//                             alt="img"
//                             style={{ width: 50, height: 50, borderRadius: 8 }}
//                           />
//                         </Box>
//                       </TableCell>
//                       <TableCell>{banner?.createdAt}</TableCell>
//                       <TableCell>
//                         <IconButton
//                           onClick={() => {
//                             navigate(`/add-banner/${crudType.READ}`, {
//                               state: banner,
//                             });
//                           }}
//                         >
//                           <Visibility sx={{ color: "#333" }} />
//                         </IconButton>
//                         <IconButton
//                           onClick={() => {
//                             //   navigate(`/edit-banner/${banner.id}`);
//                             navigate(`/add-banner/${crudType.UPDATE}`, {
//                               state: banner,
//                             });
//                           }}
//                         >
//                           <Edit sx={{ color: "#333" }} />
//                         </IconButton>
//                         <IconButton>
//                           <DeleteIcon
//                             sx={{ color: "#333" }}
//                             onClick={() => {
//                               deleteBanner(banner);
//                             }}
//                           />
//                         </IconButton>
//                       </TableCell>
//                     </TableRow>
//                   ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </Paper>
//       </Box>
//     </Container>
//   );
// };

// export default Banner;

import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Visibility, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { crudType } from "../../utils/const";
import { apiRouterCall } from "../../utils/apiRouterCall";

const Category = () => {
  const [banners, setBanners] = useState([]);
  const [filteredBanners, setFilteredBanners] = useState([]);
  const [search, setSearch] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(8);

  const listCategory = async () => {
    try {
      const res = await apiRouterCall({
        method: "GET",
        endPoint: "listBannerModule",
      });
      if (res.data) {
        console.log(res.data, "bannersbanners");
        setBanners(res.data.data);
        setFilteredBanners(res.data.data);
      }
    } catch (error) {}
  };

  const deleteBanner = async () => {
    if (selectedBanner) {
      try {
        const res = await apiRouterCall({
          method: "POST",
          endPoint: "deleteBannerModule",
          data: {
            _id: selectedBanner._id,
          },
        });
        if (res.data) {
          listCategory();
          setOpenModal(false);
        }
      } catch (error) {}
    }
  };

  const handleDeleteClick = (banner) => {
    setSelectedBanner(banner);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearch(query);

    if (query === "") {
      setFilteredBanners(banners);
    } else {
      setFilteredBanners(
        banners.filter((banner) =>
          banner.module_name.toLowerCase().includes(query.toLowerCase())
        )
      );
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    listCategory();
  }, []);

  const navigate = useNavigate();

  return (
    <Container>
      <Box
        sx={{
          padding: 3,
          minHeight: "100vh",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
          <Typography variant="h5">Category Management</Typography>
          <Button
            sx={{ bgcolor: "blue" }}
            variant="contained"
            color="primary"
            onClick={() => navigate(`/add-category/${crudType.ADD}`)}
          >
            Add Category
          </Button>
        </Box>

        <TextField
          size="small"
          placeholder="Search by Module Name"
          variant="outlined"
          value={search}
          onChange={handleSearch}
          sx={{ mb: 2, width: "30%" }}
        />

        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Category</TableCell>
                  {/* <TableCell> Images</TableCell> */}
                  <TableCell>Date Uploaded</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredBanners?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4} sx={{ textAlign: "center" }}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                ) : (
                  filteredBanners
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((banner) => (
                      <TableRow key={banner.id}>
                        <TableCell>{banner?.module_name}</TableCell>
                        {/* <TableCell>
                          <Box sx={{ display: "flex", gap: 1 }}>
                            <img
                              src={banner?.image}
                              alt="img"
                              style={{
                                width: 50,
                                height: 50,
                                borderRadius: 8,
                              }}
                            />
                          </Box>
                        </TableCell> */}
                        <TableCell>{banner?.createdAt}</TableCell>
                        <TableCell>
                          {/* <IconButton
                            onClick={() => {
                              navigate(`/add-banner/${crudType.READ}`, {
                                state: banner,
                              });
                            }}
                          >
                            <Visibility sx={{ color: "#333" }} />
                          </IconButton> */}
                          <IconButton
                            onClick={() => {
                              navigate(`/add-category/${crudType.UPDATE}`, {
                                state: banner,
                              });
                            }}
                          >
                            <Edit sx={{ color: "#333" }} />
                          </IconButton>
                          <IconButton onClick={() => handleDeleteClick(banner)}>
                            <DeleteIcon sx={{ color: "#333" }} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[8]} // Only show 8 items per page
            component="div"
            count={filteredBanners?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>

      {/* Confirmation Dialog */}
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogTitle sx={{ textAlign: "center" }}>Delete Banner</DialogTitle>
        <DialogContent sx={{ textAlign: "center" }}>
          <Typography>Are you sure you want to delete this banner?</Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={handleCloseModal}
            color="success" // Green color for "No"
            sx={{ bgcolor: "green", color: "white" }}
          >
            No
          </Button>
          <Button
            onClick={deleteBanner}
            color="error" // Red color for "Yes"
            sx={{ bgcolor: "red", color: "white" }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Category;
