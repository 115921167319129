// import React, { useState, useEffect } from "react";
// import {
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Button,
//   TablePagination,
//   CircularProgress,
//   Typography,
//   Box,
//   Container,
// } from "@mui/material";
// import { Link } from "react-router-dom";
// import axios from "axios";
// import moment from "moment";

// const UsersList = () => {
//   const [users, setUsers] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [totalDocs, setTotalDocs] = useState(0);
//   const [email, setEmail] = useState("admin@yopmail.com");

//   useEffect(() => {
//     const fetchUsers = async () => {
//       try {
//         const response = await axios.get(
//           `https://mathubackend.tarality.io/api/v1/admin/listContactUs?email=${encodeURIComponent(
//             email
//           )}&page=${page + 1}&limit=${rowsPerPage}`
//         );

//         if (response.data && Array.isArray(response.data.data.docs)) {
//           setUsers(response.data.data.docs);
//           setTotalDocs(response.data.data.totalDocs);
//         } else {
//           setError("Invalid response format");
//           console.error("Invalid response format", response.data);
//         }
//       } catch (err) {
//         setError("Failed to fetch users.");
//         console.error(err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchUsers();
//   }, [email, page, rowsPerPage]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   if (loading) {
//     return (
//       <Container>
//         <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
//           <Typography variant="h6" gutterBottom>
//             Getting data...
//           </Typography>
//           <CircularProgress />
//         </Box>
//       </Container>
//     );
//   }

//   if (error) {
//     return (
//       <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
//         <Typography variant="h6" color="error" gutterBottom>
//           {error}
//         </Typography>
//       </Box>
//     );
//   }

//   if (users.length === 0) {
//     return (
//       <Container>
//         <Box display="flex" flexDirection="column" alignItems="center" mt={15}>
//           <Typography variant="h4" gutterBottom>
//             No data found
//           </Typography>
//         </Box>
//       </Container>
//     );
//   }

//   return (
//     <Container>
//       <Box>
//         <Typography variant="h4" mb={1}>
//           Contact Users List
//         </Typography>
//         <TableContainer component={Paper}>
//           <Table sx={{ minWidth: 650 }} aria-label="simple table">
//             <TableHead>
//               <TableRow>
//                 <TableCell>
//                   <Typography variant="h6">Name</Typography>
//                 </TableCell>
//                 <TableCell>
//                   <Typography variant="h6">Email</Typography>
//                 </TableCell>
//                 <TableCell>
//                   <Typography variant="h6">Date / Time</Typography>
//                 </TableCell>
//                 <TableCell align="right">
//                   <Typography variant="h6">Details</Typography>
//                 </TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {users.map((user) => (
//                 <TableRow key={user._id}>
//                   <TableCell>{user.name}</TableCell>
//                   <TableCell>{user.email}</TableCell>
//                   <TableCell>{moment(user.createdAt).format("lll")}</TableCell>
//                   <TableCell align="right">
//                     <Button
//                       component={Link}
//                       to={`/users/${user._id}`}
//                       variant="outlined"
//                       color="primary"
//                     >
//                       View Details
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>

//         <TablePagination
//           rowsPerPageOptions={[8, 20, 30]}
//           component="div"
//           count={totalDocs}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Box>
//     </Container>
//   );
// };

// export default UsersList;

import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  CircularProgress,
  Typography,
  Box,
  Container,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { apiRouterCall } from "../utils/apiRouterCall";

const UsersList = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [totalDocs, setTotalDocs] = useState(0);
  const [email, setEmail] = useState("admin@yopmail.com");
  const [searchName, setSearchName] = useState(""); // State for name filter
  const [searchEmail, setSearchEmail] = useState(""); // State for email filter

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await apiRouterCall({
          method: "GET",
          endPoint: "listContactUs",
        });

        if (response.data && Array.isArray(response.data.data.docs)) {
          setUsers(response.data.data.docs);
          setTotalDocs(response.data.data.totalDocs);
          setFilteredUsers(response.data.data.docs); // Initialize filteredUsers with the fetched data
        } else {
          setError("Invalid response format");
          console.error("Invalid response format", response.data);
        }
      } catch (err) {
        setError("Failed to fetch users.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [email, page, rowsPerPage]);

  // Filter function for name and email
  const filterUsers = (name, email) => {
    const filtered = users.filter((user) => {
      const matchesName =
        !name || user.name.toLowerCase().includes(name.toLowerCase());
      const matchesEmail =
        !email || user.email.toLowerCase().includes(email.toLowerCase());
      return matchesName && matchesEmail;
    });
    setFilteredUsers(filtered);
    setPage(0); // Reset to the first page after filtering
  };

  // Handlers for the search inputs
  const handleNameChange = (event) => {
    const value = event.target.value;
    setSearchName(value);
    filterUsers(value, searchEmail); // Filter based on both name and email
  };

  const handleEmailChange = (event) => {
    const value = event.target.value;
    setSearchEmail(value);
    filterUsers(searchName, value); // Filter based on both name and email
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
          <Typography variant="h6" gutterBottom>
            Getting data...
          </Typography>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  if (error) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
      </Box>
    );
  }

  if (filteredUsers.length === 0) {
    return (
      <Container>
        <Box display="flex" flexDirection="column" alignItems="center" mt={15}>
          <Typography variant="h4" gutterBottom>
            No data found
          </Typography>
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <Box>
        <Typography variant="h4" mb={2}>
          Contact Users List
        </Typography>
        {/* Search Fields for Name and Email */}
        <Box sx={{ display: "flex", gap: 2, mb: 2 }}>
          <TextField
            placeholder="Search by name"
            variant="outlined"
            size="small"
            value={searchName}
            onChange={handleNameChange}
          />
          <TextField
            placeholder="Search by email"
            variant="outlined"
            size="small"
            value={searchEmail}
            onChange={handleEmailChange}
          />
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6">Name</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Email</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h6">Date / Time</Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="h6">Details</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map((user) => (
                <TableRow key={user._id}>
                  <TableCell>{user.name}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{moment(user.createdAt).format("lll")}</TableCell>
                  <TableCell align="right">
                    <Button
                      component={Link}
                      to={`/users/${user._id}`}
                      variant="outlined"
                      color="primary"
                    >
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[8, 20, 30]}
          component="div"
          count={totalDocs}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Container>
  );
};

export default UsersList;
