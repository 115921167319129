import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Box,
  Typography,
} from "@mui/material";

const LogoutModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <Typography>Logout Confirmation </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>Are you sure you want to log out?</DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "20px",
        }}
      >
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-start" }}
        >
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              bgcolor: "green",
              width: "120px",
              height: "45px",
            }}
          >
            No
          </Button>
        </Box>
        <Box
          sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            onClick={onConfirm}
            variant="contained"
            sx={{
              bgcolor: "red",
              width: "120px",
              height: "45px",
            }}
          >
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
