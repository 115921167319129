// import React from "react";
// import { Outlet, useNavigate, useLocation } from "react-router-dom";
// import {
//   Drawer,
//   List,
//   ListItem,
//   ListItemText,
//   Toolbar,
//   Box,
// } from "@mui/material";
// const Layout = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const handleLogout = () => {
//     localStorage.removeItem("isAuthenticated");
//     navigate("/login");
//   };

//   const isActive = (path) => location.pathname === path;

//   return (
//     <Box sx={{ display: "flex" }}>
//       <Drawer
//         variant="permanent"
//         sx={{
//           width: 240,
//           flexShrink: 0,
//           [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
//         }}
//       >
//         <Toolbar />
//         <List>
//           <ListItem
//             button
//             onClick={() => navigate("/dashboard")}
//             sx={{
//               cursor: "pointer",
//               backgroundColor: isActive("/dashboard")
//                 ? "#3f51b5"
//                 : "transparent",
//               color: isActive("/dashboard") ? "white" : "inherit",
//               "&:hover": {
//                 backgroundColor: isActive("/dashboard") ? "blue" : "#e0e0e0",
//               },
//             }}
//           >
//             <ListItemText primary="Dashboard" />
//           </ListItem>
//           <ListItem
//             button
//             onClick={() => navigate("/users")}
//             sx={{
//               cursor: "pointer",
//               backgroundColor: isActive("/users") ? "#3f51b5" : "transparent",
//               color: isActive("/users") ? "white" : "inherit",
//               "&:hover": {
//                 backgroundColor: isActive("/users") ? "#3f51b5" : "#e0e0e0",
//               },
//             }}
//           >
//             <ListItemText primary="Contact User" />
//           </ListItem>
//           <ListItem
//             button
//             onClick={() => navigate("/enquiry")}
//             sx={{
//               cursor: "pointer",
//               backgroundColor: isActive("/enquiry") ? "#3f51b5" : "transparent",
//               color: isActive("/enquiry") ? "white" : "inherit",
//               "&:hover": {
//                 backgroundColor: isActive("/enquiry") ? "#3f51b5" : "#e0e0e0",
//               },
//             }}
//           >
//             <ListItemText primary="Enquiry" />
//           </ListItem>
//           <ListItem
//             button
//             onClick={handleLogout}
//             sx={{
//               cursor: "pointer",
//               backgroundColor: isActive("/login") ? "#3f51b5" : "transparent",
//               color: isActive("/login") ? "white" : "inherit",
//               "&:hover": {
//                 backgroundColor: isActive("/login") ? "#3f51b5" : "#e0e0e0",
//               },
//             }}
//           >
//             <ListItemText primary="Logout" />
//           </ListItem>
//         </List>
//       </Drawer>

//       <Box
//         component="main"
//         sx={{
//           flexGrow: 1,
//           p: 3,
//           backgroundColor: "#f4f4f4",
//           minHeight: "100vh",
//         }}
//       >
//         <Toolbar />
//         <Outlet />
//       </Box>
//     </Box>
//   );
// };

// export default Layout;

// Layout.js

import React, { useState } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Box,
} from "@mui/material";
import LogoutModal from "./LogoutModal";

const Layout = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [openLogoutModal, setOpenLogoutModal] = useState(false);

  const handleLogout = () => {
    setOpenLogoutModal(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
    setOpenLogoutModal(false);
  };

  const cancelLogout = () => {
    setOpenLogoutModal(false);
  };

  const isActive = (path) => location.pathname === path;

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        variant="permanent"
        sx={{
          width: 240,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: 240, boxSizing: "border-box" },
        }}
      >
        <Toolbar />
        <List>
          <ListItem
            button
            onClick={() => navigate("/dashboard")}
            sx={{
              cursor: "pointer",
              backgroundColor: isActive("/dashboard") ? "blue" : "transparent",
              color: isActive("/dashboard") ? "white" : "inherit",
              "&:hover": {
                backgroundColor: isActive("/dashboard") ? "blue" : "#e0e0e0",
              },
            }}
          >
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/users")}
            sx={{
              cursor: "pointer",
              backgroundColor: isActive("/users") ? "blue" : "transparent",
              color: isActive("/users") ? "white" : "inherit",
              "&:hover": {
                backgroundColor: isActive("/users") ? "blue" : "#e0e0e0",
              },
            }}
          >
            <ListItemText primary="Contact User" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/enquiry")}
            sx={{
              cursor: "pointer",
              backgroundColor: isActive("/enquiry") ? "blue" : "transparent",
              color: isActive("/enquiry") ? "white" : "inherit",
              "&:hover": {
                backgroundColor: isActive("/enquiry") ? "blue" : "#e0e0e0",
              },
            }}
          >
            <ListItemText primary="Enquiry" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/banner-management")}
            sx={{
              cursor: "pointer",
              backgroundColor: isActive("/banner-management")
                ? "blue"
                : "transparent",
              color: isActive("/banner-management") ? "white" : "inherit",
              "&:hover": {
                backgroundColor: isActive("/banner-management")
                  ? "blue"
                  : "#e0e0e0",
              },
            }}
          >
            <ListItemText primary="Banner Management" />
          </ListItem>
          <ListItem
            button
            onClick={() => navigate("/category-management")}
            sx={{
              cursor: "pointer",
              backgroundColor: isActive("/category-management")
                ? "blue"
                : "transparent",
              color: isActive("/category-management") ? "white" : "inherit",
              "&:hover": {
                backgroundColor: isActive("/category-management")
                  ? "blue"
                  : "#e0e0e0",
              },
            }}
          >
            <ListItemText primary="Category Management" />
          </ListItem>

          <ListItem
            button
            onClick={handleLogout}
            sx={{
              cursor: "pointer",
              backgroundColor: isActive("/login") ? "blue" : "transparent",
              color: isActive("/login") ? "white" : "inherit",
              "&:hover": {
                backgroundColor: isActive("/login") ? "blue" : "#e0e0e0",
              },
            }}
          >
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          // p: 3,
          backgroundColor: "#f4f4f4",
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        {children}
      </Box>

      <LogoutModal
        open={openLogoutModal}
        onClose={cancelLogout}
        onConfirm={confirmLogout}
      />
    </Box>
  );
};

export default Layout;
