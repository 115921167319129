import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Paper,
  Typography,
  Box,
  CircularProgress,
  Alert,
  IconButton,
  Grid,
  Container,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { apiRouterCall } from "../utils/apiRouterCall";

const UserDetails = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const email = "admin@yopmail.com";
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await apiRouterCall({
          method: "GET",
          endPoint: "viewContactUs",
          params: {
            _id: userId,
          },
        });

        if (response.data && response.data.data) {
          setUser(response.data.data);
        } else {
          setError("User not found.");
        }
      } catch (err) {
        setError("Failed to fetch user details.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId, email]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Container>
      <Paper sx={{ p: 3 }}>
        <Box sx={{ mb: 2 }}>
          <IconButton onClick={handleBack} aria-label="back">
            <ArrowBackIcon sx={{ color: "black" }} />
          </IconButton>
        </Box>

        <Container>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Typography>ID: {user._id}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Name: {user.name}</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={6}>
              <Typography>Email: {user.email}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography>Message: {user.message}</Typography>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Container>
  );
};
export default UserDetails;
