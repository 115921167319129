import React from "react";
import { Box, Typography, Paper, Container } from "@mui/material";

const Dashboard = () => {
  return (
    <Container>
      <Box
        sx={{
          backgroundColor: "#f5f5f5",
          minHeight: "100vh",
          padding: 4,
          justifyContent: "center",
          textAlign: "center",
          alignItems: "center",
        }}
      >
        <Paper
          sx={{
            backgroundColor: "#3f51b5",
            color: "#fff",
            padding: 3,
            borderRadius: 2,
            boxShadow: 2,
          }}
        >
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Welcome to the Admin Dashboard
          </Typography>
          <Typography variant="body1" sx={{ marginTop: 1 }}>
            Manage users, view analytics, and more.
          </Typography>
        </Paper>
      </Box>
    </Container>
  );
};

export default Dashboard;
