import axios from "axios";
import { apiConfig } from "./apiConfig";

export const apiRouterCall = async ({
  method,
  id,
  endPoint,
  data,
  params,
  token,
  source,
  url,
  onUploadProgress,
}) => {
  try {
    return axios({
      method: method,
      url: url
        ? url
        : id
        ? `${apiConfig[endPoint]}/${id}`
        : apiConfig[endPoint],
      headers: {
        token: token ? token : window.localStorage.getItem("token"),
      },
      data: data ? data : null,
      params: params ? params : null,
      cancelToken: source ? source.token : null,
      onUploadProgress: onUploadProgress
        ? (progressEvent) => {
            // Call the provided onUploadProgress function
            onUploadProgress(progressEvent);
          }
        : undefined, // Pass undefined if no function is provided
    });
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
