export const baseUrl = "https://mathubackend.tarality.io";
// export const baseUrl = "http://172.16.16.20:5019";
export const version = "api/v1";

export const apiConfig = {
  imageUpload: `${baseUrl}/${version}/admin/uploadImage`,
  listInquiry: `${baseUrl}/${version}/admin/listInquiry`,
  listContactUs: `${baseUrl}/${version}/admin/listContactUs`,
  viewInquiry: `${baseUrl}/${version}/admin/viewInquiry`,
  viewContactUs: `${baseUrl}/${version}/admin/viewContactUs`,
  createImageWithDescription: `${baseUrl}/${version}/admin/createImageWithDescription`,
  loginAdmin: `${baseUrl}/${version}/admin/loginAdmin`,
  listImage: `${baseUrl}/${version}/admin/listImage`,
  editDescription: `${baseUrl}/${version}/admin/editDescription`,
  deleteImage: `${baseUrl}/${version}/admin/deleteImage`,
  listBannerModule: `${baseUrl}/${version}/admin/listBannerModule`,
  deleteBannerModule: `${baseUrl}/${version}/admin/deleteBannerModule`,
  createBannerModule: `${baseUrl}/${version}/admin/createBannerModule`,
  editBannerModule: `${baseUrl}/${version}/admin/editBannerModule`,
};
