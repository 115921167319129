import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "./components/  LoginPage";
import Dashboard from "./pages/ Dashboard";
import UsersList from "./pages/ UsersList";
import UserDetails from "./components/UserDetails";

import Layout from "./components/Layout";
import EnquiryList from "./pages/EnquiryList";
import EnquiryDetails from "./components/EnquiryDetails";
// import AddEditBanner from "./pages/bannermanagement/AddBanner";
import Banner from "./pages/bannermanagement/Banner";
// import Edit from "./pages/bannermanagement/Edit";
import AddBanner from "./pages/bannermanagement/AddBanner";
import PrivateRoute from "./PrivateRoute";
import Category from "./pages/CategoryManagement/Category";
import AddCategory from "./pages/CategoryManagement/AddCategory";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route element={<PrivateRoute />}>
          <Route
            path="dashboard"
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />

          <Route
            path="users"
            element={
              <Layout>
                <UsersList />
              </Layout>
            }
          />
          <Route
            path="users/:userId"
            element={
              <Layout>
                <UserDetails />
              </Layout>
            }
          />

          <Route
            path="enquiry"
            element={
              <Layout>
                <EnquiryList />
              </Layout>
            }
          />
          <Route
            path="enquiry/:userId"
            element={
              <Layout>
                <EnquiryDetails />
              </Layout>
            }
          />

          <Route
            path="banner-management"
            element={
              <Layout>
                <Banner />
              </Layout>
            }
          />
          <Route
            path="/add-category/:type"
            element={
              <Layout>
                <AddCategory />
              </Layout>
            }
          ></Route>
          <Route
            path="category-management"
            element={
              <Layout>
                <Category />
              </Layout>
            }
          />

          <Route
            path="/add-banner/:type"
            element={
              <Layout>
                <AddBanner />
              </Layout>
            }
          />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
